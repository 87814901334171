<template>
    <div class="hero-container object-header-v2">
        <object-header-image
            v-if="data?.images || data?.image"
            :images="data.images"
            :herotype="data.herotype"
            :image="data.image"
            :size-of-hero="data.sizeOfHero"
        />

        <div class="header-title-wrapper">
            <v-container :class="isHome ? 'hero-index' : ''">
                <object-header-headline-v2
                    :headline="data.headline"
                    :sub-headline="data.subHeadline"
                    :subAlign="data.subAlign"
                    :class="data.sizeOfHero"
                />
            </v-container>
        </div>
    </div>
</template>

<script setup>
import {defineProps} from "vue";
import ObjectHeaderHeadlineV2 from "@/components/object_header/object_header_headlineV2.vue";
import ObjectHeaderImage from "@/components/object_header/object_header_image.vue";
import {useThemeStore} from "@/store/theme";

const
    path = document.location.pathname.slice(1),
    themeStore = useThemeStore();

const isHome = ['', themeStore.homepage].includes(path);

defineProps({
    data: Object,
});
</script>

<style lang="scss" scoped>
@import "@/scss/variables/breakpoints.scss";

.object-header-v2 {
    background-color: gray;
    position: relative;

    .v-container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
        z-index: 2;

        :deep(h1.header-container) {
            color: var(--color-white);

            @include breakpoint("lg") {
                margin: 0 4rem;
            }

            .subheadline {
                margin-bottom: 0;
            }

            .main-headline {
                font-family: var(--secondary-font);
            }

            &.small {
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: normal !important;

                span {
                    font-weight: normal !important;

                    &.main-headline {
                        font-size: clamp(2rem, 4vw, 4rem);
                    }
                }
            }
        }
    }

    :deep(.hero-img) {
        max-height: unset;
        max-width: unset;

        &.small {
            min-height: 24rem;
            height: 25vh;

            img {
                filter: brightness(0.5);
            }
        }

        &.big {
            min-height: 40rem;
            height: 66vh;

            img {
                filter: brightness(0.8);
            }
        }

        .v-carousel {
            height: 100% !important;

            .v-carousel-item {
                filter: brightness(0.50) saturate(.75);
            }

            button {
                background-color: transparent;
                box-shadow: none;

                i {
                    color: white;
                }
            }
        }
    }
}
</style>
