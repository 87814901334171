<template>
    <div class="team-members">

        <div v-if="data?.captain" class="captain">
            <h3>Captain:</h3>
            <template v-for="(captain, index) in data.captain" :key="index">
                <single-member :member="captain"/>
                <template v-if="index + 1 !== data?.captain.length">{{ ` ${themeStore.teamMember.delimiter} ` }}</template>
            </template>
        </div>

        <div v-if="data?.vizeCaptain" class="vice-captain">
            <h3>Vize-Captain:</h3>
            <template v-for="(vizeCaptain, index) in data.vizeCaptain" :key="index">
                <single-member :member="vizeCaptain"/>
                <template v-if="index + 1 !== data?.vizeCaptain.length">{{ ` ${themeStore.teamMember.delimiter} ` }}</template>
            </template>
        </div>

        <div v-if="data?.member" class="member">
            <h3>Mannschaftskader:</h3>
            <template v-for="(member, index) in data.member" :key="index">
                <single-member :member="member?.element"/>
                <template v-if="index + 1 !== data.member.length">{{ ` ${themeStore.teamMember.delimiter} ` }}</template>
            </template>
        </div>

        <div v-if="data?.trainer" class="trainer">
            <h3>Trainer:</h3>
            <template v-for="(trainer, index) in data?.trainer" :key="index">
                <single-member :member="trainer"/>
                <template v-if="index + 1 !== data?.trainer.length">{{ ` ${themeStore.teamMember.delimiter} ` }}</template>
            </template>
        </div>
    </div>
</template>

<script setup>
import {defineProps} from "vue";
import SingleMember from "@/pages/pageTeam/singleMember.vue";
import { useThemeStore } from '@/store/theme'

const themeStore = useThemeStore();

defineProps({
    data: {
        type: Object,
        required: true,
    },
});
</script>
