<template>
    <div class="documents-list">
        <template
            v-for="(file, index) in data.content"
            :key="index"
        >
            <a
                v-if="file.element.filename.endsWith('pdf')"
                class="link-container hover-background-color"
                :href="api + file.element.original"
                v-ripple
                target="_blank"
            >
                <custom-image
                    :image="{ fullpath: '/frontend-icons/pdf.png' }"
                    container-width="auto"
                    height="100%"
                    :hover="false"
                    container-height="auto"
                />
                <p class="file link">
                    {{ file.element.filename.substring(0, file.element.filename.lastIndexOf('.')) }}
                </p>
            </a>
        </template>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue'
    import CustomImage from '@/components/reuseables/customImage.vue'

    defineProps({
        data: {
            type: Object,
            required: true,
        },
    })

    const api = process.env.VUE_APP_API_URL
</script>
