<template>
    <v-row class="news-overview-v2">
        <template v-for="(news, k) in listing">
            <v-col
                v-if="k < 4"
                cols="12"
                md="6"
                lg="4"
                :key="k"
                :class="'news-' + k"
                v-ripple
            >
                <router-link :to="{ name: 'news', params: { slug: news?.node?.slug }}">
                    <custom-image
                        :image="getNewsImage(news)"
                        container-height="auto"
                        cover
                    />
                    <div class="text-container">
                        <p class="meta-information">
                            {{ formatMetaInformation(news) }}
                        </p>
                        <h3 v-if="news?.node?.texts?.[0]?.headline">
                            {{ news.node.texts[0].headline }}
                        </h3>
                        <custom-button class="read-more">
                            Alles lesen
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-arrow-right"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                            </svg>
                        </custom-button>
                    </div>
                </router-link>
            </v-col>
        </template>
    </v-row>

    <div
        v-if="data.newsAllLinks?.[0]?.dataObject?.url"
        class="show-more-news theme-show-more-news d-none"
    >
        <custom-button
            class="all-news-button"
            :to="'/' + data.newsAllLinks[0]?.dataObject?.url"
        >
            {{ data.newsAllLinks[0]?.title }}
        </custom-button>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue'
    import CustomImage from '@/components/reuseables/customImage.vue'
    import CustomButton from '@/components/reuseables/customButton.vue'
    import { getNewsImage } from '@/mixins/global/globalMethods'

    defineProps({
        data: Object,
        items: Object,
        listing: Object,
        content: Object,
    })

    function formatMetaInformation(news) {
        const metaInformationArray = []
        const authors = news?.node?.author?.map((author) => `${author.firstname} ${author.lastname}`).join(', ')
        const categories = news.node.categories?.map((category) => category.name).join(', ') ?? ''

        if (authors) metaInformationArray.push(authors)
        if (news.node.date) metaInformationArray.push(news.node.date)
        if (categories) metaInformationArray.push(categories)

        return metaInformationArray.join(' / ')
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables/breakpoints.scss';

    .news-overview-v2 {
        [class*='news-'] {
            a {
                display: flex;
                flex-direction: column;
                text-decoration: none;
                height: 100%;

                :deep(.image-container) {
                    aspect-ratio: 16/9;

                    .v-responsive__sizer {
                        display: none;
                    }
                }

                .text-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2rem;
                    padding: 2rem;
                    flex-grow: 1;

                    h3 {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .custom-button {
                        min-height: 2rem;
                        margin-top: auto;

                        svg {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            &.news-3 {
                display: none;
                @include breakpoint('md') {
                    display: block;
                }
            }
        }
    }
</style>
