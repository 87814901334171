import { defineStore } from 'pinia'
import themeQuery from '@/graphql/querie/themeConfig.graphql'
import { apolloProvider } from '@/plugins/apollo'

export const useThemeStore = defineStore('theme', {
    state: () => ({
        homepage: null,
        newsfeed: null,
        news: { defaultImage: null, overviewPage: null },
        teamMember: { delimiter: null, defaultImage: null },
        trainer: { overviewPage: null },
        events: { overviewPage: null },
        teams: { overviewPage: null },
        lessons: { overviewPage: null },
        translations: {},
        placeholders: {},
        defaultHeroTemplate: null,
    }),
    actions: {
        setHomepage(value) {
            this.homepage = value
        },
        setNewsFeed(value) {
            this.newsfeed = value
        },
        setNewsDefaultImage(value) {
            this.news.defaultImage = value
        },
        setTeamMemberDefaultImage(value) {
            this.teamMember.defaultImage = value
        },
        setTeamMembersDelimiter(value) {
            this.teamMember.delimiter = value || '-'
        },
        setOverviewPage(target, value) {
            this[target].overviewPage = value
        },
        setDefaultHeroTemplate(value) {
            this.defaultHeroTemplate = value
        },
        setTranslations(translations) {
            this.translations = {}
            translations?.forEach((translation) => {
                this.translations[translation.translationKey] = translation.translationValue
            })
        },
        translate(key) {
            return this.translations[key] ?? key
        },
        setPlaceholders(placeholders) {
            this.placeholders = {}
            placeholders?.forEach((placeholder) => {
                this.placeholders[placeholder.placeholderKey] = placeholder.placeholderValue
            })
        },
        processPlaceholders(text) {
            return Object.keys(this.placeholders).length ?
                text?.replace(/\{\{\s*(\w+)\s*}}/g, (match, key) => {
                    return this.placeholders[key] ?? match;
                }) :
                text;
        },
        loadTheme() {
            apolloProvider.defaultClient
                .query({
                    query: themeQuery,
                })
                .then((response) => {
                    const theme = response?.data?.getThemeConfigListing?.edges[0]?.node

                    if (theme) {
                        this.setNewsFeed(theme?.newsfeed)
                        this.setDefaultHeroTemplate(theme?.defaultHeroTemplate)
                        this.setNewsDefaultImage(theme?.newsDefaultImage)
                        this.setTeamMemberDefaultImage(theme?.teamMemberDefaultImage)
                        this.setTeamMembersDelimiter(theme?.teamMembersDelimiter)

                        this.setHomepage(theme?.homepage)
                        this.setOverviewPage('news', theme?.newsOverviewPage)
                        this.setOverviewPage('trainer', theme?.trainerOverviewPage)
                        this.setOverviewPage('events', theme?.eventOverviewPage)
                        this.setOverviewPage('lessons', theme?.lessonsOverviewPage)
                        this.setOverviewPage('teams', theme?.teamsOverviewPage)

                        this.setTranslations(theme?.translations)
                        this.setPlaceholders(theme?.placeholders)
                    }
                })
                .catch((error) => {
                    console.error('Error loading theme config:', error)
                })
        },
    },
})
