<template>
    <custom-loading v-if="loading" />
    <component
        v-else-if="res.length > 0 || data || data.content || items"
        :is="components[data.template]"
        :data="data"
        :content="data.content"
        :items="items"
        :listing="res"
    />
</template>

<script setup>
// /* eslint-disable */
import {ref} from "vue";
import {defineProps, onMounted} from "vue";
import {apolloProvider} from "@/plugins/apollo";
import newsListing from "@/graphql/querie/listing/newsListing.graphql";
import holeOverview from "@/components/listings/holeOverview";
import newsOverview from "@/components/listings/newsOverview";
import newsOverviewV2 from "@/components/listings/newsOverviewV2.vue";
import imageStripe from "@/components/listings/imageStripe.vue";
import imageBoxBig from "@/components/listings/imageBoxBig.vue";
import teamOverview from "@/components/listings/teamOverview.vue";
import teamOverviewV2 from "@/components/listings/teamOverviewV2.vue";
import newsAll from "@/components/listings/newsAll.vue";
import newsAllV2 from "@/components/listings/newsAllV2.vue";
import lessonBox from "@/components/listings/lessonBox.vue";
import lessonsList from "@/components/listings/lessonsList.vue";
import documentsList from "@/components/listings/documentsList.vue";
import partnerClubList from "@/components/listings/partnerClubList.vue";
import CustomLoading from "@/components/reuseables/loading.vue";

const components = {
        newsOverview,
        newsOverviewV2,
        imageStripe,
        imageBoxBig,
        holeOverview,
        teamOverview,
        teamOverviewV2,
        newsAll,
        newsAllV2,
        lessonBox,
        lessonsList,
        documentsList,
        partnerClubList
    },
    props = defineProps({
        data: Object,
        items: Object,
    }),
    loading = ref(false),
    res = ref([]),
    listingObject = {newsListing};

function loadListing() {
    res.value = [];
    if (props.data.listing) {
        loading.value = true
        apolloProvider.defaultClient
            .query({
                fetchPolicy: "no-cache",
                query: listingObject[props.data.listing + "Listing"],
                variables: {
                    lang: "de",
                },
            })
            .then((response) => {
                res.value =
                    response.data[
                    "get" +
                    props.data.listing.charAt(0).toUpperCase() +
                    props.data.listing.slice(1) +
                    "Listing"
                        ].edges;
            })
            .finally(() => {
                loading.value = false;
            });
    }
}

onMounted(loadListing);
</script>
