<template>
    <a
        :href="getLink()"
        target="_blank"
        class="image-box hover-background-color"
        v-ripple
        :data-id="data.id"
    >
        <custom-image
            v-if="data?.fullpath"
            :image="data"
            contain
            class="image"
            :hover="false"
        />
        <custom-image
            v-if="data?.images"
            :image="data.images[0]"
            contain
            class="image"
            :hover="false"
            @click="onImageClick"
        />
        <custom-image
            v-if="data?.logo"
            :image="data.logo[0]"
            contain
            class="image"
            :hover="false"
        />
    </a>
    <vue-easy-lightbox
        teleport="body"
        v-if="data?.images"
        :visible="visibleRef"
        :imgs="imgsRef"
        :index="indexRef"
        @hide="onHide"
        rotate-disabled
        zoom-disabled
        pinch-disabled
        move-disabled
    />
</template>

<script setup>
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox'
import { getImagesForLightBox } from '@/mixins/global/globalMethods'

const props = defineProps({
    data: Object,
});

const {
    onHide,
    visibleRef,
    indexRef,
    imgsRef,
} = useEasyLightbox({
    imgs: getImagesForLightBox(props.data?.images),
    initIndex: 0,
});

function onImageClick(event) {
    if (props?.data?.clickBehavior === 'lightbox') {
        event.preventDefault();
        visibleRef.value = !visibleRef.value;
        indexRef.value = 0;
    }
}

function getLink() {
    if (props.data.links) {
        return props.data.links[0].link;
    }
    return "";
}
</script>

<style lang="scss" scoped>
a {
    display: block;
    padding: 2rem;
    color: white;
    .image {
        height: 4rem;
        width: 100%;
        background-size: contain;
    }
}
</style>
