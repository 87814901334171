<template>
    <div v-if="gallery" class="gallery">
        <headline-container class="headline-gallery" mb="xs">{{ galleryHeadline || 'Bildergalerie' }}</headline-container>
        <custom-gallery :gallery="gallery" />
    </div>
    <div v-if="simpleLinks.length" class="links">
        <headline-container class="headline-links" mb="xs">{{ linksHeadline || 'Weiterführende Links' }}</headline-container>
        <custom-links :links="simpleLinks"/>
    </div>
    <template v-if="Object.keys(navigationGroups).length">
        <div v-for="group in Object.keys(navigationGroups)" class="links" :key="group">
            <headline-container class="headline-links" mb="xs">{{ group || 'Weiterführende Links' }}</headline-container>
            <custom-links :links="navigationGroups[group]"/>
        </div>
    </template>
    <div v-if="files" class="files">
        <headline-container class="headline-files" mb="xs">{{ filesHeadline || 'PDF' }}</headline-container>
        <custom-files :files="files" />
    </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import CustomFiles from "@/components/reuseables/customFiles.vue";
import CustomLinks from "@/components/reuseables/customLinks.vue";
import CustomGallery from "@/components/reuseables/customGallery.vue";

const simpleLinks = ref([]);
const navigationGroups = ref({});

const props = defineProps({
    galleryHeadline: String,
    linksHeadline: String,
    filesHeadline: String,
    gallery: Object,
    links: Object,
    files: Object,
});

onMounted(() => {
    if (props.links) {
        props.links.forEach((link) => {
            if (link.group) {
                if (!navigationGroups.value[link.group]) {
                    navigationGroups.value[link.group] = [];
                }
                navigationGroups.value[link.group].push(link);
            } else {
                simpleLinks.value.push(link);
            }
        });
    }
});
</script>
