<template>
    <div :class="['custom-swiper', 'slider-' + (data.template ? data.template : data.listing)]">
        <swiper
            @swiper="setupInstance"
            :breakpoints="breakpoints"
            :modules="modules"
            :pagination="{
                enabled: Boolean(config.pagination),
                clickable: true,
            }"
            :navigation="{
                nextEl: 'swiper-button-next-custom',
                prevEl: 'swiper-button-prev-custom'
            }"
            :loop="Boolean(config.loop)"
        >
            <swiper-slide
                v-for="(item, i) in listing"
                :key="i"
                :class="'slide-' + getItemType(item)"
            >
                <component
                    :is="components[getItemType(item)]"
                    :data="data.content ? item.element : item"
                />
            </swiper-slide>
        </swiper>
        <div class="swiper-button-prev-custom" @click="customSwiper?.slidePrev()" v-if="config.navigation"></div>
        <div class="swiper-button-next-custom" @click="customSwiper?.slideNext()" v-if="config.navigation"></div>
    </div>
</template>

<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Navigation, Pagination } from 'swiper/modules'
    import { computed, defineProps, onMounted, ref } from 'vue'
    import { apolloProvider } from '@/plugins/apollo'
    import newsListing from '@/graphql/querie/listing/newsListing.graphql'
    import object_news from '@/components/object_news.vue'
    import object_teaser from '@/components/object_Teaser.vue'
    import object_testimonial from '@/components/object_testimonial.vue'
    import imageBox from '@/components/listings/imageBox.vue'

    const components = { object_testimonial, object_news, object_teaser, imageBox }

    const props = defineProps({
            data: Object,
            items: Object,
            config: Object,
        }),
        modules = [Navigation, Pagination],
        loading = ref(false),
        listing = ref([]),
        listingObject = { newsListing },
        customSwiper = ref(null)

    function setupInstance(swiperInstance) {
        customSwiper.value = swiperInstance
    }

    const breakpoints = computed(() => {
        const breakpoints = { 0: {} }

        if (props.config.breakpoints) {
            props.config.breakpoints.forEach((breakpoint) => {
                const minWidth = breakpoint.minWidth ? breakpoint.minWidth : '0'
                const options = {}
                breakpoint.options.forEach((option) => {
                    options[option.option] = isNaN(option.optionValue) ? option.optionValue : Number(option.optionValue)
                })
                breakpoints[minWidth] = options
            })
        }

        return breakpoints
    })

    function getItemType(item) {
        let type;

        if (props.data.template) {
            type = props.data.template
        } else if (props.data.content) {
            type = item.element.__typename
        } else {
            type = `object_${props.data.listing}`
        }

        return type
    }

    function loadListing() {
        if (listingObject[props.data.listing + 'Listing']) {
            loading.value = true
            apolloProvider.defaultClient
                .query({
                    fetchPolicy: 'no-cache',
                    query: listingObject[props.data.listing + 'Listing'],
                    variables: {
                        lang: 'de',
                    },
                })
                .then((response) => {
                    listing.value =
                        response.data[
                            'get' + props.data.listing.charAt(0).toUpperCase() + props.data.listing.slice(1) + 'Listing'
                        ].edges
                })
                .finally(() => {
                    loading.value = false
                })
        }
    }

    onMounted(() => {
        if (props.data.content) {
            listing.value = props.data.content
        } else {
            loadListing()
        }
    })
</script>
