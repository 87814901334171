<script setup>
import {defineProps, onMounted, ref} from "vue";

defineProps({
  navigationRoot: {
    type: Object,
    required: true,
  }
});

const panels = ref([]);

function generatePanelsFromUrl(url) {
  const segments = url.split('/').filter(segment => segment);
  const panelsArray = [];
  let currentPath = '';
  segments.forEach(segment => {
    currentPath += (currentPath ? '/' : '') + segment;
    panelsArray.push(currentPath);
  });
  return panelsArray;
}

onMounted(() => {
  const currentUrl = window.location.pathname.substring(1);
  panels.value = generatePanelsFromUrl(currentUrl);
});
</script>

<template>
  <div class="simple-page-side-navigation">
    <router-link :to="'/' + navigationRoot?.url" class="navigation-root no-children">
      {{ navigationRoot?.urlText }}
    </router-link>

    <v-expansion-panels v-if="navigationRoot?.children?.length" v-model="panels" multiple>
      <template v-for="(firstLevelPage, key) in navigationRoot?.children" :key="key">
        <v-expansion-panel v-if="firstLevelPage?.children && firstLevelPage?.children?.length > 0" elevation="0"
                           :value="firstLevelPage?.url">
          <v-expansion-panel-title>
            <span class="not-a-router">
              {{ firstLevelPage?.urlText }}
            </span>
            <template v-slot:actions="{ expanded }">
              <v-icon :icon="expanded ? 'mdi-minus' : 'mdi-plus'"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-expansion-panels multiple>
              <template v-for="(secondLevelPage, key) in firstLevelPage?.children" :key="key">
                <v-expansion-panel v-if="secondLevelPage?.children && secondLevelPage?.children?.length > 0"
                                   elevation="0"
                                   :value="secondLevelPage?.url">
                  <v-expansion-panel-title>
                    <router-link :to="'/' + secondLevelPage?.url" class="second-level-page">
                      {{ secondLevelPage?.urlText }}
                    </router-link>
                    <template v-slot:actions="{ expanded }">
                      <v-icon :icon="expanded ? 'mdi-minus' : 'mdi-plus'"></v-icon>
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <router-link v-for="(thirdLevelPage, key) in secondLevelPage?.children"
                                 :to="'/' + thirdLevelPage?.url" :key="key" class="third-level-page no-children">
                      {{ thirdLevelPage?.urlText }}
                    </router-link>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <router-link v-else :to="'/' + secondLevelPage?.url" class="second-level-page no-children">
                  {{ secondLevelPage?.urlText }}
                </router-link>
              </template>
            </v-expansion-panels>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <router-link v-else :to="'/' + firstLevelPage?.url" class="no-children">
          {{ firstLevelPage?.urlText }}
        </router-link>
      </template>
    </v-expansion-panels>
  </div>
</template>

<style scoped lang="scss">
.headline-container {
  margin: 1rem 0 !important;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}

:deep(.v-expansion-panel-title__icon) {
  width: 4rem;
  justify-content: center;
}

:deep(.v-expansion-panel) {
  &::after {
    content: none;
  }
}

a, .not-a-router {
  display: block;
  width: 100%;
  padding: .5rem 1rem;
  z-index: 9;
  text-decoration: none;
  font-size: .875rem;

  &.second-level-page {
    padding-left: 2rem;
  }

  &.third-level-page {
    padding-left: 3rem;
  }
}

</style>