<template>
    <div v-if="!pageHasHero" :style="{ height: appBarHeight + 'px' }"></div>

    <v-app-bar ref="appBar"
               elevation="0"
               :class="{
                    'scrolled': isScrolled,
                    'has-hero': pageHasHero,
                    'no-hero': !pageHasHero,
                    'app-bar-mobile': $isMobile() || windowSize.x < 1280
               }"
    >
        <v-container>
            <navigation-header-logos :header="header" />

            <navigation-header-desktop
                v-if="!$isMobile() && windowSize.x >= 1280"
                :header="header"
            />
            <navigation-header-mobile v-else :header="header"/>
        </v-container>
    </v-app-bar>
</template>

<script setup>
import {defineProps, onMounted, onUnmounted, ref, onUpdated} from "vue";
import NavigationHeaderMobile from "@/components/navigation/navigationHeader/navigationHeaderMobile.vue";
import NavigationHeaderDesktop from "@/components/navigation/navigationHeader/navigationHeaderDesktop.vue";
import NavigationHeaderLogos from "@/components/navigation/navigationHeader/navigationHeaderLogos.vue";

defineProps({
    header: {
        type: Object,
        required: true,
    },
});

const windowSize = ref({ x: window.innerWidth, y: window.innerHeight }),
    appBar = ref(null),
    appBarHeight = ref(0),
    pageHasHero = ref(false),
    isScrolled = ref(false);

function updateWindowSize() {
    windowSize.value = { x: window.innerWidth, y: window.innerHeight };
}

onMounted(() => {
    window.addEventListener('scroll', checkScroll);
    window.addEventListener('heroMountedStatus', handleHeroMountedStatus);
    window.addEventListener('resize', updateWindowSize);
});

onUnmounted(() => {
    window.removeEventListener('scroll', checkScroll);
    window.removeEventListener('heroMountedStatus', handleHeroMountedStatus);
    window.removeEventListener('resize', updateWindowSize);
});
function checkScroll() {
    isScrolled.value = window.pageYOffset > appBar.value.$el.offsetHeight;
}

function handleHeroMountedStatus(event) {
    pageHasHero.value = event.detail.status === 'mounted';
}

onUpdated(() => {
    appBarHeight.value = appBar.value.$el.offsetHeight;
});
</script>

<style lang="scss" scoped>
.desktop-navigation {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
    max-width: calc(100% - 5rem);
    float: right;
}

.primary-button,
.secondary-button {
    font-size: 1.125rem;

    * {
        font-size: 1.125rem;
    }
}

.v-container {
    position: relative;
}

header {
    overflow: initial;

    :deep(.v-toolbar__content) {
        height: 100% !important;
    }
}

.v-container {
    overflow: initial;
}

.v-list {
    .v-list-item {
        padding: 0 !important;

        .v-list-item-title {
            padding: 0.25rem 1rem;
            font-size: 1.125rem;
            text-transform: lowercase;
        }
    }
}

.mobile-navigation-button {
    color: var(--color-white) !important;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    max-width: calc(100% - 5rem);
    float: right;
}
</style>
